import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../../components/Icon"
import Select from "../../../components/Select"
import Checkbox from "../../../components/Checkbox"
import SettingsSchedule from '../SettingsSchedule'

import { dateToInfoBlock, formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../../funcs"
import { useAppSelector } from '../../../store/hooks'

import { CallCenter_CallFlow, CallCenter_CallGroup, CallCenter_PhoneNumber } from "../../../models/CallCenter"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/entity-edit.sass"
import Switcher from "../../../components/Switcher";

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallFlowData
}

interface CallFlowData {

  permissions: {
    call_flow_add: boolean
    call_flow_delete: boolean
    call_flow_edit: boolean
    call_flow_report_show: boolean
    call_flow_show: boolean
    call_group_show: boolean
    phone_number_show: boolean

    call_center_schedule_add: boolean
    call_center_schedule_delete: boolean
    call_center_schedule_edit: boolean
    call_center_schedule_show: boolean
  },

  call_flow: CallCenter_CallFlow,
  call_groups: CallCenter_CallGroup[],
  phone_numbers: CallCenter_PhoneNumber[],

  edit: {

    call_groups: CallCenter_CallGroup[]
  }
}

const emptyCallGroup: Partial<CallCenter_CallGroup> = {
  call_group_id: '',
  name: '',
  dispatchers: [{ code: 0, nickname: '' }],
  call_flows: ['']
}

function CallCenter_CallFlowsPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [editing, setEditing] = useState(false)

  const [page, setPage] = useState('main')

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  const [callFlowData, setCallFlowData] = useState<CallFlowData | null>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [callGroups, setCallGroups] = useState<CallCenter_CallGroup[] | null>(null)
  const [availableCallGroups, setAvailableCallGroups] = useState<Partial<CallCenter_CallGroup>[]>([])
  const [selectedCallGroup, setSelectedCallGroup] = useState<Partial<CallCenter_CallGroup>>(emptyCallGroup)

  const [callGroupsData, setCallGroupsData] = useState<CallCenter_CallGroup[]>([])

  const [phoneNumbers, setPhoneNumbers] = useState<CallCenter_PhoneNumber[]>([])

  const [newCallFlowData, setNewCallFlowData] = useState({
    name: '',
    caller_id: false,
    phrase_start: '',
    phrase_office_closed: '',
    phrase_office_temporary_closed: '',
    phrase_phone_not_available: '',
    phrase_dispatcher_not_available: ''
  })

  function handleCancelEdit() {
    setEditing(false)

    callFlowData && setNewCallFlowData({
      name: callFlowData.call_flow.name,
      caller_id: callFlowData.call_flow.caller_id,
      phrase_start: callFlowData.call_flow.phrase_start,
      phrase_office_closed: callFlowData.call_flow.phrase_office_closed,
      phrase_office_temporary_closed: callFlowData.call_flow.phrase_office_temporary_closed,
      phrase_phone_not_available: callFlowData.call_flow.phrase_phone_not_available,
      phrase_dispatcher_not_available: callFlowData.call_flow.phrase_dispatcher_not_available
    })
  }

  // remove document
  async function handleRemoveItem() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.delete(`/callcenter/callflows/${$router.router.getState().params.callFlowsId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.callFlows', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      const {
        name, phrase_start, phrase_office_closed, phrase_office_temporary_closed,
        phrase_phone_not_available, phrase_dispatcher_not_available, caller_id
      } = newCallFlowData

      const response = await httpClientUpdate.put(`/callcenter/callflows/${$router.router.getState().params.callFlowsId}`, qs.stringify({
        account_id: activeAccountId,
        name, phrase_start, phrase_office_closed, phrase_office_temporary_closed,
        phrase_phone_not_available, phrase_dispatcher_not_available,
        call_groups: JSON.stringify(callGroupsData.map(item => item.call_group_id && item.call_group_id)),
        caller_id: !!caller_id ? 1 : 0,
      }))

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: callFlowData, success, error } } = (await httpClientUpdate.get('/callcenter/callflows/' + $router.router.getState().params.callFlowsId, {
        params: {
          account_id: activeAccountId,
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        if (callFlowData.permissions.call_flow_show) {
          setPhoneNumbers(callFlowData.phone_numbers)

          setCallFlowData(callFlowData)

          setCallGroups(callFlowData.edit.call_groups)
          setCallGroupsData(callFlowData.call_groups)

          let _availableCallGroups = []

          _availableCallGroups = callFlowData.edit.call_groups.filter(($callGroup) => {

            return callFlowData.call_groups.findIndex((callGroup) => {
              return callGroup.call_group_id === $callGroup.call_group_id
            }) === -1
          })

          setAvailableCallGroups([emptyCallGroup].concat(_availableCallGroups))

          setSelectedCallGroup(emptyCallGroup)

          setNewCallFlowData({
            name: callFlowData.call_flow.name,
            caller_id: callFlowData.call_flow.caller_id,

            phrase_start: callFlowData.call_flow.phrase_start,
            phrase_office_closed: callFlowData.call_flow.phrase_office_closed,
            phrase_office_temporary_closed: callFlowData.call_flow.phrase_office_temporary_closed,
            phrase_phone_not_available: callFlowData.call_flow.phrase_phone_not_available,
            phrase_dispatcher_not_available: callFlowData.call_flow.phrase_dispatcher_not_available
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load callFlow data
  useEffect(() => {
    page === 'main' && loadInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => setReadyToSave(true), [
    newCallFlowData
  ])

  function handleBackSettings() {
    setPage('main')
  }

  // Add callGroup function
  function addCallGroup() {

    if (!callGroupsData || !newCallFlowData) return

    const newCallGroupsList = [
      ...callGroupsData,
      selectedCallGroup
    ]

    setCallGroupsData(newCallGroupsList as any)

    let _availableCallGroups = [...availableCallGroups]

    _availableCallGroups = _availableCallGroups.filter(($callGroup) => {

      return newCallGroupsList.findIndex((callGroup) => {
        return callGroup.call_group_id === $callGroup.call_group_id
      }) === -1
    })

    setAvailableCallGroups([
      ..._availableCallGroups
    ])

    setSelectedCallGroup(emptyCallGroup)

    setReadyToSave(true)
  }

  // Remove callGroup function
  function removeCallGroup(i: number) {

    let _callFlowCallGroups = [...callGroupsData]

    let _callGroup = _callFlowCallGroups[i]

    _callFlowCallGroups.splice(i, 1)

    setCallGroupsData(_callFlowCallGroups)

    setAvailableCallGroups([
      ...availableCallGroups,
      _callGroup
    ])

    setReadyToSave(true)
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {
      page === 'main' ?
        <>
          {callFlowData && newCallFlowData.name ? (
            <div className="CallCenter_CallFlowsPage_Item entity-edit">

              {/* Top navigation */}
              <div className="top-nav">
                <BaseLink
                  router={$router.router}
                  routeName="call_center.phoneNumbers"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
                >
                  <Icon icon="phone-1" />
                  <span>Phone Numbers</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.dispatchers"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
                >
                  <Icon icon="user-1" />
                  <span>Dispatchers</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.additionalTime"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
                >
                  <Icon viewBox="0 0 1024 1024" icon="time-22" />
                  <span>Additional Time</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.absence"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.absence" })}
                >
                  <Icon icon="time-16" />
                  <span>Absence</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.groups"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.groups" })}
                >
                  <Icon icon="user-29" />
                  <span>Groups</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.callFlows"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
                >
                  <Icon icon="share-7" />
                  <span>Call Flows</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.caller_groups"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
                >
                  <Icon icon="user-29" />
                  <span>Caller Groups</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.temporaryClosed"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
                >
                  <Icon viewBox="0 0 1024 1024" icon="close-664" />
                  <span>Temporary Closed</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.callCenters"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.callCenters" })}>
                  <Icon icon="delivery-8" />
                  <span>Call Centers</span>
                </BaseLink>

                <BaseLink
                  router={$router.router}
                  routeName="call_center.audioLibrary"
                  routeParams={{ companyId: activeAccountId }}
                  className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
                >
                  <Icon viewBox="0 0 1024 1024" icon="audio-15" />
                  <span>Audio Library</span>
                </BaseLink>
              </div>

              <div className="wrapper flex-container sb">
                <h1>Call Flow</h1>

                <div style={{ display: 'flex' }} >
                  <button
                    disabled={editing}
                    style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                    className="_wa"
                    onClick={() =>
                      $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'call_center.callFlows',
                        {
                          companyId: activeAccountId,
                          [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                          localInterface: $router.router.getState().params.localInterface
                        }, { reload: true })}
                  >
                    <Icon
                      style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                      viewBox="0 0 24 24"
                      icon="arrow-25"
                    />
                  </button>

                  {
                    <button
                      className="_wa"
                      style={{ background: '#d0d3da', color: 'white', marginRight: '20px' }}
                      disabled={editing}
                      onClick={() => setPage('schedule')}
                    >
                      Schedule
                    </button>
                  }

                  {
                    !editing &&
                      callFlowData?.permissions.call_flow_edit ? (
                      <button className="_wa _green" onClick={() => setEditing(true)}>
                        Edit
                      </button>
                    ) : null
                  }
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Main</div>

                <div className="fields">
                  <div className="__right">
                    <div className="field">
                      <span>Name:</span>
                      <input
                        className={classNames({
                          _error: errorFields.includes('name')
                        })}
                        type="text"
                        disabled={!editing}
                        value={newCallFlowData.name}
                        onChange={({ target: { value } }) => {
                          setNewCallFlowData({ ...newCallFlowData, name: value })
                          errorFields.includes('name') && setErrorFields(errorFields.filter(item => item !== 'name'))
                        }}
                      />
                    </div>
                  </div>

                  <div className="__left">
                    <div className="field">
                      <span>Show Caller Phone:</span>

                      <Switcher
                        disabled={!editing}
                        checked={newCallFlowData.caller_id}
                        onChange={(value) => setNewCallFlowData({ ...newCallFlowData, caller_id: value })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="flex-container sb wrap">
                  <div className="legend">Call Groups</div>

                  <div className="legend-action-wrapper">
                    <label>Call Group:</label>
                    <div className="input-wrapper">
                      <Select
                        disabled={!editing}
                        options={availableCallGroups.map((callGroup) => ({
                          span: callGroup.name as string,
                          value: callGroup.name as string
                        }))}
                        selectedOption={selectedCallGroup.name as string}
                        onChange={(value) => setSelectedCallGroup(availableCallGroups.find((_callGroup) => _callGroup.name === value) as CallCenter_CallGroup)} />
                      <button
                        disabled={!selectedCallGroup.name}
                        className="_green"
                        onClick={() => addCallGroup()}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <table className={classNames('table', '__hide-on-mobile', {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                })}>
                  <tr>
                    <th>Name</th>
                    <th>Dispatchers</th>
                    <th>Call Flow</th>
                    <th></th>
                  </tr>
                  {(callGroupsData as CallCenter_CallGroup[]).map((callGroup, i) => (
                    <tr
                      key={i}
                      style={{ cursor: callFlowData.permissions.call_group_show && callGroup.call_group_id ? 'pointer' : 'inherit', position: 'relative' }}
                      onClick={() =>
                        callFlowData.permissions.call_group_show &&
                        callGroup.call_group_id &&
                        $router.router.navigate('call_center.groups.item', {
                          companyId: activeAccountId,
                          groupsId: callGroup.call_group_id,
                          fromPage: 'call_center.callFlows.item',
                          nameId: 'callFlowsId',
                          id: $router.router.getState().params.callFlowsId,
                          localInterface: $router.router.getState().params.localInterface
                        }, { reload: true })}
                      onContextMenu={(event) =>
                        callFlowData.permissions.call_group_show &&
                        callGroup.call_group_id &&
                        handleChangeMenu(event, callGroup.call_group_id, 'groups', i)}
                    >
                      <td>{callGroup.name}</td>
                      <td>{callGroup.dispatchers.map((item, index) => (
                        index === 0 ?
                          item.code ? `${item.nickname} (${item.code})` : item.nickname :
                          item.code ? `, ${item.nickname} (${item.code})` : `, ${item.nickname}`
                      ))}</td>
                      <td>{typeof callGroup.call_flows === 'string' ? callGroup.call_flows : callGroup.call_flows.join(', ')}</td>
                      <td>
                        <button
                          className="_zeroed _iconed _red"
                          disabled={!editing}
                          onClick={(e) => { e.stopPropagation(); removeCallGroup(i) }}
                        >
                          <Icon icon="x-mark-1" />
                        </button>
                        {
                          callFlowData.permissions.call_group_show &&
                          showContext === `groups_${callGroup.call_group_id}_${i}` &&
                          <div
                            className="redirect-menu"
                            ref={contextMenuRef}
                            style={{ left: `${screenX}px` }}
                          >
                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                $router.router.navigate('call_center.groups.item', {
                                  companyId: activeAccountId,
                                  groupsId: callGroup.call_group_id,
                                  fromPage: 'call_center.callFlows.item',
                                  nameId: 'callFlowsId',
                                  id: $router.router.getState().params.callFlowsId,
                                  localInterface: $router.router.getState().params.localInterface
                                }, { reload: true })
                              }
                              }
                            >
                              Open
                            </button>

                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                window.open(`${window.location.origin}/${activeAccountId}/call_center/groups/${callGroup.call_group_id}`, "_blank", 'noopener,noreferrer')
                                setShowContext('')
                              }}
                            >
                              Open in new tab
                            </button>
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
                </table>

                <div className={classNames('mobile-table', '__show-on-mobile', {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                })}>

                  <div className="mobile-table-items">

                    {callGroupsData.map((callGroup, i: number) => (
                      <div
                        key={i}
                        className="item"
                        onClick={() =>
                          callFlowData.permissions.call_group_show &&
                          callGroup.call_group_id &&
                          $router.router.navigate('call_center.groups.item', {
                            companyId: activeAccountId,
                            groupsId: callGroup.call_group_id,
                            fromPage: 'call_center.callFlows.item',
                            nameId: 'callFlowsId',
                            id: $router.router.getState().params.callFlowsId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })}
                      >
                        <div className="__top">
                          <div className="__left">
                            <div><b>{callGroup.name}</b></div>
                            <div>
                              {callGroup.dispatchers.map((item, index) => (
                                index === 0 ?
                                  item.code ? `${item.nickname} (${item.code})` : item.nickname :
                                  item.code ? `, ${item.nickname} (${item.code})` : `, ${item.nickname}`
                              ))}
                            </div>
                          </div>

                          <div className="__right">
                          </div>
                        </div>

                        <div className="__bottom">
                          <div className="__left">
                          </div>

                          <div className="__right small">
                            <div>
                              <b>{callGroup.call_flows}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Call Phrases</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field _ait">
                      <span>Start Phrase:</span>
                      <textarea
                        className={classNames({
                          _error: errorFields.includes('phrase_start')
                        })}
                        value={newCallFlowData.phrase_start}
                        disabled={!editing}
                        onChange={({ target: { value } }) => {
                          setNewCallFlowData({ ...newCallFlowData, phrase_start: value })
                          errorFields.includes('phrase_start') && setErrorFields(errorFields.filter(item => item !== 'phrase_start'))
                        }}></textarea>
                    </div>

                    <div className="field _ait">
                      <span>Office Closed:</span>
                      <textarea
                        className={classNames({
                          _error: errorFields.includes('phrase_office_closed')
                        })}
                        value={newCallFlowData.phrase_office_closed}
                        disabled={!editing}
                        onChange={({ target: { value } }) => {
                          setNewCallFlowData({ ...newCallFlowData, phrase_office_closed: value })
                          errorFields.includes('phrase_office_closed') && setErrorFields(errorFields.filter(item => item !== 'phrase_office_closed'))
                        }}></textarea>
                    </div>

                    <div className="field _ait">
                      <span>Office Temporary Closed:</span>
                      <textarea
                        className={classNames({
                          _error: errorFields.includes('phrase_office_temporary_closed')
                        })}
                        value={newCallFlowData.phrase_office_temporary_closed}
                        disabled={!editing}
                        onChange={({ target: { value } }) => {
                          setNewCallFlowData({ ...newCallFlowData, phrase_office_temporary_closed: value })
                          errorFields.includes('phrase_office_temporary_closed') && setErrorFields(errorFields.filter(item => item !== 'phrase_office_temporary_closed'))
                        }}></textarea>
                    </div>
                  </div>

                  <div className="__right">
                    <div className="field _ait">
                      <span>Phone Number Not Available:</span>
                      <textarea
                        className={classNames({
                          _error: errorFields.includes('phrase_phone_not_available')
                        })}
                        value={newCallFlowData.phrase_phone_not_available}
                        disabled={!editing}
                        onChange={({ target: { value } }) => {
                          setNewCallFlowData({ ...newCallFlowData, phrase_phone_not_available: value })
                          errorFields.includes('phrase_phone_not_available') && setErrorFields(errorFields.filter(item => item !== 'phrase_phone_not_available'))
                        }}></textarea>
                    </div>

                    <div className="field _ait">
                      <span>Dispatchers Not Available:</span>
                      <textarea
                        className={classNames({
                          _error: errorFields.includes('phrase_dispatcher_not_available')
                        })}
                        value={newCallFlowData.phrase_dispatcher_not_available}
                        disabled={!editing}
                        onChange={({ target: { value } }) => {
                          setNewCallFlowData({ ...newCallFlowData, phrase_dispatcher_not_available: value })
                          errorFields.includes('phrase_dispatcher_not_available') && setErrorFields(errorFields.filter(item => item !== 'phrase_dispatcher_not_available'))
                        }}></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Info</div>

                <div className="fields">
                  <div className="__left">
                    <div className="field">
                      <span>Date Created:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, callFlowData.call_flow.created_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Created by:</span>
                      <input type="text" defaultValue={callFlowData.call_flow.created_by} disabled={true} />
                    </div>
                  </div>


                  <div className="__right">

                    <div className="field">
                      <span>Last Edited:</span>
                      <input
                        type="text"
                        defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, callFlowData.call_flow.updated_at)}
                        disabled={true}
                      />
                    </div>

                    <div className="field">
                      <span>Last Edited by:</span>
                      <input type="text" defaultValue={callFlowData.call_flow.updated_by} disabled={true} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fieldset">
                <div className="legend">Phone Numbers</div>

                {/* Wide desktop table */}
                <table className={classNames('table', '__show-on-wide', {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                })}>
                  <tr>
                    <th>Phone Number</th>
                    <th>Friendly Name</th>
                    <th>Type</th>
                    <th>Source</th>
                    <th>Area</th>
                    <th>Company</th>
                    <th>Call Flow</th>
                    <th>Available</th>
                  </tr>
                  {phoneNumbers.map((phoneNumber, i) => (
                    <tr
                      key={i}
                      style={{ cursor: callFlowData.permissions.phone_number_show && phoneNumber.phone_number_id ? 'pointer' : 'inherit', position: 'relative' }}
                      onClick={() =>
                        callFlowData.permissions.phone_number_show &&
                        phoneNumber.phone_number_id &&
                        $router.router.navigate('call_center.phoneNumbers.item', {
                          companyId: activeAccountId,
                          phoneNumbersId: phoneNumber.phone_number_id,
                          fromPage: 'call_center.callFlows.item',
                          nameId: 'callFlowsId',
                          id: $router.router.getState().params.callFlowsId,
                          localInterface: $router.router.getState().params.localInterface
                        }, { reload: true })}
                      onContextMenu={(event) =>
                        callFlowData.permissions.phone_number_show &&
                        phoneNumber.phone_number_id &&
                        handleChangeMenu(event, phoneNumber.phone_number_id, 'phoneNumbers', i)}
                    >
                      <td>
                        <div className="flex-container">
                          <span>{formatPhoneNumber(phoneNumber.phone)}</span>
                          <button className="_zeroed _iconed">
                            <Icon icon="gear-1" />
                          </button>
                        </div>
                      </td>
                      <td>{phoneNumber.friendly_name}</td>

                      <td className={classNames({
                        'red-text': phoneNumber.type === 'System',
                        'green-text': phoneNumber.type === 'Source',
                        'blue-text': !['System', 'Source'].includes(phoneNumber.type)
                      })}>{phoneNumber.type}</td>

                      <td>{phoneNumber.source}</td>
                      <td>{phoneNumber.area}</td>
                      <td>{phoneNumber.company}</td>
                      <td>{phoneNumber.call_flow}</td>

                      <td className={classNames({
                        'red-text': phoneNumber.active === 'Off',
                        'green-text': phoneNumber.active === 'On',
                      })}>
                        {phoneNumber.active}
                        {
                          callFlowData.permissions.phone_number_show &&
                          showContext === `phoneNumbers_${phoneNumber.phone_number_id}_${i}` &&
                          <div
                            className="redirect-menu"
                            ref={contextMenuRef}
                            style={{ left: `${screenX}px` }}
                          >
                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                $router.router.navigate('call_center.phoneNumbers.item', {
                                  companyId: activeAccountId,
                                  phoneNumbersId: phoneNumber.phone_number_id,
                                  fromPage: 'call_center.callFlows.item',
                                  nameId: 'callFlowsId',
                                  id: $router.router.getState().params.callFlowsId,
                                  localInterface: $router.router.getState().params.localInterface
                                }, { reload: true })
                              }
                              }
                            >
                              Open
                            </button>

                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                window.open(`${window.location.origin}/${activeAccountId}/call_center/phoneNumbers/${phoneNumber.phone_number_id}`, "_blank", 'noopener,noreferrer')
                                setShowContext('')
                              }}
                            >
                              Open in new tab
                            </button>
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
                </table>

                {/* Medium screen table */}
                <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                })}>
                  <tr></tr>
                  {phoneNumbers.map((phoneNumber, i) => (
                    <tr
                      key={i}
                      style={{ cursor: callFlowData.permissions.phone_number_show && phoneNumber.phone_number_id ? 'pointer' : 'inherit', position: 'relative' }}
                      onClick={() =>
                        callFlowData.permissions.phone_number_show &&
                        phoneNumber.phone_number_id &&
                        $router.router.navigate('call_center.phoneNumbers.item', {
                          companyId: activeAccountId,
                          phoneNumbersId: phoneNumber.phone_number_id,
                          fromPage: 'call_center.callFlows.item',
                          nameId: 'callFlowsId',
                          id: $router.router.getState().params.callFlowsId,
                          localInterface: $router.router.getState().params.localInterface
                        }, { reload: true })}
                      onContextMenu={(event) =>
                        callFlowData.permissions.phone_number_show &&
                        phoneNumber.phone_number_id &&
                        handleChangeMenu(event, phoneNumber.phone_number_id, 'phoneNumbers', i)}
                    >
                      <td>
                        <div>{formatPhoneNumber(phoneNumber.phone)}</div>
                        <div>{phoneNumber.friendly_name}</div>
                      </td>
                      <td>
                        <div>{phoneNumber.source}</div>
                        <div>{phoneNumber.area}</div>
                      </td>
                      <td>
                        <div>{phoneNumber.company}</div>
                        <div className={classNames({
                          'red-text': phoneNumber.type === 'System',
                          'green-text': phoneNumber.type === 'Source',
                          'blue-text': !['System', 'Source'].includes(phoneNumber.type)
                        })}>{phoneNumber.type}</div>
                      </td>
                      <td>
                        <div>{phoneNumber.call_flow}</div>
                        <div className={classNames({
                          'red-text': phoneNumber.active === 'Off',
                          'green-text': phoneNumber.active === 'On',
                        })}>{phoneNumber.active}</div>
                        {
                          callFlowData.permissions.phone_number_show &&
                          showContext === `phoneNumbers_${phoneNumber.phone_number_id}_${i}` &&
                          <div
                            className="redirect-menu"
                            ref={contextMenuRef}
                            style={{ left: `${screenX}px` }}
                          >
                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                $router.router.navigate('call_center.phoneNumbers.item', {
                                  companyId: activeAccountId,
                                  phoneNumbersId: phoneNumber.phone_number_id,
                                  fromPage: 'call_center.callFlows.item',
                                  nameId: 'callFlowsId',
                                  id: $router.router.getState().params.callFlowsId,
                                  localInterface: $router.router.getState().params.localInterface
                                }, { reload: true })
                              }
                              }
                            >
                              Open
                            </button>

                            <button
                              onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                window.open(`${window.location.origin}/${activeAccountId}/call_center/phoneNumbers/${phoneNumber.phone_number_id}`, "_blank", 'noopener,noreferrer')
                                setShowContext('')
                              }}
                            >
                              Open in new tab
                            </button>
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
                </table>

                {/* Mobile table items */}
                <div className={classNames('mobile-table-items', '__show-on-mobile', {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                })}>

                  {phoneNumbers.map((phoneNumber, i: number) => (
                    <div
                      className="item"
                      key={i}
                      onClick={() =>
                        callFlowData.permissions.phone_number_show &&
                        phoneNumber.phone_number_id &&
                        $router.router.navigate('call_center.phoneNumbers.item', {
                          companyId: activeAccountId,
                          phoneNumbersId: phoneNumber.phone_number_id,
                          fromPage: 'call_center.callFlows.item',
                          nameId: 'callFlowsId',
                          id: $router.router.getState().params.callFlowsId,
                          localInterface: $router.router.getState().params.localInterface
                        }, { reload: true })}
                    >
                      <div className="__top">
                        <div className="__left">
                          <div><b>{formatPhoneNumber(phoneNumber.phone)}</b></div>
                          <div>
                            {phoneNumber.friendly_name}
                          </div>
                        </div>

                        <div className="__right">
                          <div>
                            {phoneNumber.company}
                          </div>
                          <div className={classNames({
                            'red-text': phoneNumber.type === 'System',
                            'green-text': phoneNumber.type === 'Source',
                            'blue-text': !['System', 'Source'].includes(phoneNumber.type)
                          })}>
                            {phoneNumber.type}
                          </div>
                          <div className={classNames({
                            'red-text': phoneNumber.active === 'Off',
                            'green-text': phoneNumber.active === 'On',
                          })}>
                            {phoneNumber.active}
                          </div>
                        </div>
                      </div>

                      <div className="__bottom">
                        <div className="__left">
                          <div>
                            <span className="gray">Area:</span>
                            <span> {phoneNumber.area}</span>
                          </div>
                          <div>
                            <span className="gray">Source:</span>
                            <span> {phoneNumber.source}</span>
                          </div>
                        </div>

                        <div className="__right small">
                          <div>
                            <b>{phoneNumber.call_flow}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {editing ? (
                <div className="wrapper flex-container sb editing-buttons">
                  <div>
                    {
                      callFlowData.permissions.call_flow_delete &&
                      <button
                        className="_red"
                        onClick={() => setDeleting(true)}>
                        Delete Call Flow
                      </button>
                    }
                  </div>
                  <div className="buttons">
                    <button
                      className="_bordered _red"
                      onClick={() => handleCancelEdit()}
                    >
                      Cancel
                    </button>
                    <button
                      className="_bordered _green"
                      disabled={!readyToSave || !!errorFields.length || isSaving}
                      onClick={() => handleSave()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : null}

              {/* Item share popup */}
              {callFlowData.permissions.call_flow_delete &&
                deleting ? (
                <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                  <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                    <div className="title">
                      Delete Call Flow
                    </div>

                    <div className="checkbox-wrapper">
                      <Checkbox contents="I understand that after deleting the call flow it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                    </div>

                    <div className="buttons">

                      <button
                        className="_bordered _green"
                        onClick={() => $router.router.navigate('call_center.callFlows', {
                          companyId: activeAccountId
                        }, {
                          reload: true
                        })}
                      >
                        Cancel
                      </button>

                      <button
                        className="_bordered _red"
                        disabled={!readyToDelete || isSaving}
                        onClick={() => handleRemoveItem()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </> :
        <SettingsSchedule
          handleBackSettings={handleBackSettings}
          permissions={
            {
              call_center_schedule_add: callFlowData?.permissions.call_center_schedule_add ? callFlowData?.permissions.call_center_schedule_add : false,
              call_center_schedule_delete: callFlowData?.permissions.call_center_schedule_delete ? callFlowData?.permissions.call_center_schedule_delete : false,
              call_center_schedule_edit: callFlowData?.permissions.call_center_schedule_edit ? callFlowData?.permissions.call_center_schedule_edit : false,
              call_center_schedule_show: callFlowData?.permissions.call_center_schedule_show ? callFlowData?.permissions.call_center_schedule_show : false,

              call_center_settings_edit: true,
              call_center_settings_show: true,

              twilio_info_edit: true,
              twilio_info_show: true,
            }
          }
        />
    }
  </>)
}

export default CallCenter_CallFlowsPage_Item
