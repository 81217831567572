import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import moment, { Moment } from "moment"
import Datetime from 'react-datetime'

import Icon from "../../components/Icon"
import Checkbox from "../../components/Checkbox"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from "../../funcs"
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReportData
}

interface ReportData {

  permissions: {
    additional_time_service_resource_delete: boolean
    additional_time_service_resource_edit: boolean
  },

  additional_time: AdditionalTime
}

interface AdditionalTime {
  additional_time_end: Moment | Date | string;
  additional_time_id: string
  additional_time_start: Moment | Date | string;
  created_at: string
  created_by: string
  service_resource_id: string
  updated_at: string
  updated_by: string

  service_resource_code: number
  service_resource_nickname: string
  service_resource_time_zone: string
}

function AbsencesPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [reportData, setReportData] = useState<ReportData | null>(null)

  const [newData, setNewData] = useState<Partial<AdditionalTime>>({
  })

  function handleCancelEdit() {
    setEditing(false)
    if (reportData) {
      const dateStart = dateToInfoBlock('MM/dd/yyyy hh:mm a', reportData.additional_time.service_resource_time_zone, reportData.additional_time.additional_time_start as string)
      const dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mm a', reportData.additional_time.service_resource_time_zone, reportData.additional_time.additional_time_end as string)

      setNewData({
        ...reportData.additional_time,
        additional_time_start: dateStart,
        additional_time_end: dateEnd,
      })
    }
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/additional-times/88888222wk0vn2xe1l
      const response = await httpClientUpdate.delete(`/additional-times/${$router.router.getState().params.additionalTimeId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('additional-time', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      let start = moment.isMoment(newData.additional_time_start) ? moment(newData.additional_time_start).format('MM/DD/YYYY hh:mm a') : newData.additional_time_start
      let end = moment.isMoment(newData.additional_time_end) ? moment(newData.additional_time_end).format('MM/DD/YYYY hh:mm a') : newData.additional_time_end


      // https://2022back4.artemiudintsev.com/api/additional-times/88888222wk0vn2xe1l
      const response = await httpClientUpdate.put(`/additional-times/${$router.router.getState().params.additionalTimeId}`, {
        account_id: activeAccountId,
        additional_time_start: updatedDateToReqServer('MM/dd/yyyy hh:mm a', newData.service_resource_time_zone, start as string),
        additional_time_end: updatedDateToReqServer('MM/dd/yyyy hh:mm a', newData.service_resource_time_zone, end as string),
      })

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/additional-times/88888222wk0vn2xe1l?account_id=88888
      const { data: { data: responseData, success, error } } = await httpClientUpdate.get('/additional-times/' + $router.router.getState().params.additionalTimeId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        const dateStart = dateToInfoBlock('MM/dd/yyyy hh:mm a', responseData.additional_time.service_resource_time_zone, responseData.additional_time.additional_time_start as string)
        const dateEnd = dateToInfoBlock('MM/dd/yyyy hh:mm a', responseData.additional_time.service_resource_time_zone, responseData.additional_time.additional_time_end as string)

        setReportData(responseData)
        setNewData({
          ...responseData.additional_time,
          additional_time_start: dateStart,
          additional_time_end: dateEnd,
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  // Render function
  return (<>
    {reportData ? (
      <div className="AbsencesPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <h1>Additional Time</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'additional-time',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                reportData.permissions.additional_time_service_resource_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field" style={{ zIndex: 6 }}>
                <span>Service resource:</span>

                <input
                  type="text"
                  defaultValue={newData.service_resource_code ? `${newData.service_resource_nickname}(${newData.service_resource_code})` : newData.service_resource_nickname}
                  disabled={true}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Start:</span>
                <Datetime
                  dateFormat="MM/DD/YYYY"
                  timeFormat="hh:mm A"
                  inputProps={{ disabled: !editing }}
                  value={newData.additional_time_start as Date}
                  onChange={(value) => setNewData({ ...newData, additional_time_start: value })}
                />
              </div>

              <div className="field">
                <span>End:</span>
                <Datetime
                  dateFormat="MM/DD/YYYY"
                  timeFormat="hh:mm A"
                  inputProps={{ disabled: !editing }}
                  value={newData.additional_time_end as Date}
                  onChange={(value) => setNewData({ ...newData, additional_time_end: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.additional_time.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input
                  type="text"
                  defaultValue={reportData.additional_time.created_by}
                  disabled={true}
                />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.additional_time.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input
                  type="text"
                  defaultValue={reportData.additional_time.updated_by}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                reportData.permissions.additional_time_service_resource_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Additional time</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {
          reportData.permissions.additional_time_service_resource_delete &&
            deleting ? (
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Additional time
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox contents="I understand that after deleting the additional time it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete || isSaving}
                    className="_bordered _red"
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : null}
      </div>
    ) : null}
  </>)
}

export default AbsencesPage_Item