import { useState, useEffect, useCallback } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import ReactInputMask from 'react-input-mask'
import { saveAs } from 'file-saver'

import Icon from '../../components/Icon'
import Select from "../../components/Select"
import ModalImage from './ModalImage'
import ErrorImage from '../../images/error_image.svg'

import { useAppSelector } from '../../store/hooks'
import { dateToInfoBlock, formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import '../../styles/pages/sms.sass'

interface HttpDialogReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    message_dialog: {
      message_dialog_id: string
      messages: MessagesProps[]
    }
  }
}

interface MessagesProps {
  created_at: string
  created_by: string
  direction: string
  message: string
  is_read: boolean
  items: {
    type: string
    url: string
  }[]
}

interface HttpGetAccountsReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    phone_numbers: AccountsProps[]
  }
}

interface AccountsProps {
  friendly_name: string
  phone: string
  phonenumber_id: string
}

export default function ItemMessage() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [message, setMessage] = useState('')

  const [chat, setChat] = useState<MessagesProps[] | []>([])
  const [dialogsId, setDialogsId] = useState('')

  const [data, setData] = useState({
    customer: '',
    company: '',
    company_phone: '',
  })
  const [accountsData, setAccountsData] = useState<AccountsProps[] | []>([])

  const [openModal, setOpenModal] = useState(false)
  const [zoomImage, setZoomImage] = useState({ url: '', type: '' })
  const [uploadImage, setUploadImage] = useState<File[] | []>([])

  const [isSendMessage, setIsSendMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [draging, setDraging] = useState(false)

  const onDragEnter = useCallback((e) => {
    setDraging(true);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    setDraging(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    let updateImages = uploadImage.map(item => item)
    updateImages.push(files[0])
    setUploadImage(updateImages)
    setDraging(false);
    return false;
  }, [uploadImage]);

  useEffect(() => {
    window.addEventListener('dragenter', onDragEnter);
    window.addEventListener('mouseup', onDragLeave);
    window.addEventListener('dragover', onDragOver);
    return () => {
      window.removeEventListener('dragenter', onDragEnter);
      window.removeEventListener('mouseup', onDragLeave);
      window.removeEventListener('dragover', onDragOver);
    };
  }, [onDragEnter, onDragLeave, onDragOver]);

  // Get contacts
  async function getAccounts() {
    // https://2022back4.artemiudintsev.com/api/callcenter/phonenumbers/sources?account_id=88888
    try {
      const { data: { data: accountsData, success, error } } = await httpClientUpdate.get('/callcenter/phonenumbers/sources',
        {
          params: {
            account_id: activeAccountId,
          }
        }) as { data: HttpGetAccountsReport }
      if (success) {
        setAccountsData(accountsData.phone_numbers)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Get dialogs
  async function getDialogs() {
    // https://2022back4.artemiudintsev.com/api/messages/dialog/messages?account_id=88888&from=%2B18132605462&to=%2B17277619799
    try {
      const { data: { data: dialogsData, success, error } } = await httpClientUpdate.get('/messages/dialog/messages',
        {
          params: {
            account_id: activeAccountId,
            from: formatPhoneNumberToServerString(data.customer),
            to: formatPhoneNumberToServerString(data.company_phone),
          }
        }) as { data: HttpDialogReport }

      if (success) {
        setChat(dialogsData.message_dialog.messages)
        setDialogsId(dialogsData.message_dialog.message_dialog_id)
      } else {
        setErrorMessage(error.message)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      setErrorMessage(createdError.content.errorText)
    }
  }

  useEffect(() => {
    getAccounts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data.company && formatPhoneNumberToServerString(data.customer).length === 12) {
      getDialogs()
    }
    if (errorMessage) {
      setErrorMessage('')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  // Send message function
  async function handleSendMessage() {
    setIsSaving(true)
    let from = formatPhoneNumberToServerString(data.customer)
    let to = formatPhoneNumberToServerString(data.company_phone)
    const formData = new FormData();
    setIsSendMessage(true)

    try {
      uploadImage.length && uploadImage.forEach((item, index) => {
        formData.append(
          `media_content_${index}`,
          item,
          item.name
        );
      })
      activeAccountId && formData.append('account_id', activeAccountId);
      formData.append('message', message);
      from && formData.append('from', from);
      to && formData.append('to', to);
      formData.append('num_media', `${uploadImage.length}`)

      let response = await httpClientUpdate.post(`/messages/send`, formData);
      if (response.data.success) {
        // loadInfo()
        setIsSaving(false)
        setMessage('')
        setUploadImage([])
        setIsSendMessage(false)
        setErrorMessage('')
        setDraging(false)
        if (dialogsId) {
          $router.router.navigate('sms.item', {
            companyId: activeAccountId,
            dialogId: dialogsId,
            selectedCallCenter: $router.router.getState().params.selectedCallCenter,
          }, { reload: true })
        }
      } else {
        setIsSaving(false)
        setIsSendMessage(false)
        setDraging(false)
        setErrorMessage(response.statusText)
      }

    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      setIsSendMessage(false)
      setDraging(false)
      let createdError = nErrorUpdate(error)
      setErrorMessage(createdError.content.errorText)
    }
  }

  function handleChangeMessage(event: any) {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      message !== '' && handleSendMessage()
    }
  }

  function handleOpenImage(url: string, type: string) {
    setZoomImage({ url, type })
    setOpenModal(true)
  }

  function handleUploadImages(image: File) {
    let updateImages = uploadImage.map(item => item)
    updateImages.push(image)
    setUploadImage(updateImages)
  }

  function handleRemoveImage(index: number) {
    let updateImages = uploadImage.filter((item, idx) => index !== idx)
    setUploadImage(updateImages)
  }

  const downloadImage = (url: string, type: string) => {

    saveAs(url, type.replace('/', '.')) // Put your image url here.
  }

  function isValidURL(string: string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g);
    return (res !== null)
  };

  function checkSendButton() {
    let check = false

    if (data.customer.length <= 16) {
      check = true
    }
    if (data.company === '') {
      check = true
    }

    return check
  }

  return (
    <div className="SmsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>SMS:</h1>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
          className="_wa"
          onClick={() =>
            $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'sms',
              {
                companyId: activeAccountId,
                [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                localInterface: $router.router.getState().params.localInterface,
                selectedCallCenter: $router.router.getState().params.selectedCallCenter,
              }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>


      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Customer:</span>
              <ReactInputMask
                type="text"
                mask="+1 (999) 999-9999"
                value={data.customer}
                onChange={({ target: { value } }) => setData({ ...data, customer: value })}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Company:</span>
              <Select
                options={accountsData.map((item) => ({
                  span: `${item.friendly_name}  (${item.phone})`,
                  value: item.phonenumber_id
                }))}
                selectedOption={data.company}
                onChange={(value) => setData({ ...data, company: value as string, company_phone: accountsData.filter(item => item.phonenumber_id === value)[0].phone })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">
          Messages
          {
            errorMessage &&
            <span style={{ fontSize: '12px', color: '#FF0000' }}> {errorMessage}</span>
          }
        </div>

        {
          draging && uploadImage.length < 10 ?
            <div
              style={{
                justifyContent: 'space-between',
                gridGap: 'inherit',
                textAlign: 'center',
                border: '1px solid #d0d3da',
                backgroundColor: 'white',
                position: 'relative',
                borderRadius: '5px'
              }}
              className="fields"
            >
              <input
                id="dragbox"
                style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                type="file"
                name="myImage"
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onChange={(event) => {
                  event.target.files && handleUploadImages(event.target.files[0])
                  setDraging(false)
                }}
              />
              <span
                style={{ position: 'absolute', top: '60px', width: '100%' }}
              >
                Upload an image or click for refresh field
              </span>
            </div> :
            <div
              style={{
                justifyContent: 'space-between',
                gridGap: 'inherit',
                textAlign: 'center',
                border: '1px solid #d0d3da',
                backgroundColor: 'white',
                borderRadius: '5px'
              }}
              className="fields"
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  position: 'relative'
                }}
              >
                <textarea
                  style={{ width: '100%', border: 'none' }}
                  placeholder='Your Message'
                  value={message}
                  onChange={({ target: { value } }) => setMessage(value)}
                  onKeyDown={(event) => handleChangeMessage(event)}
                />

              </div>

              {
                !!uploadImage.length &&
                <div
                  className='upload-image'
                  style={{
                    width: '100%',
                  }}
                >
                  <div
                    className='show-images'
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      width: '100%',
                      background: 'white',
                      padding: '20px',
                    }}
                  >
                    {
                      uploadImage.map((item, index) => (
                        <div className='show-image' key={index} style={{ position: 'relative' }}>
                          <img
                            style={{ maxHeight: '100px', maxWidth: '100px', objectFit: 'cover' }}
                            alt="not found"
                            src={URL.createObjectURL(item)}
                          />
                          <span
                            onClick={() => handleRemoveImage(index)}
                          >
                            <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                          </span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '5px'
                }}
              >
                {
                  uploadImage.length < 10 ?
                    <label
                      htmlFor="filePicker"
                      className='add-image'
                      title='Upload a image'
                    >
                      <Icon style={{ transform: 'rotate(45deg)' }} viewBox="0 0 24 24" icon="x-mark-1" />
                    </label> :
                    <div></div>
                }
                <input
                  disabled={uploadImage.length > 9}
                  id="filePicker"
                  style={{ visibility: "hidden" }}
                  type="file"
                  name="myImage"
                  onChange={(event) => {
                    event.target.files && handleUploadImages(event.target.files[0])
                  }}
                />
                <button
                  className='send'
                  disabled={(message === '' && !uploadImage.length) || isSendMessage || checkSendButton() || isSaving}
                  onClick={() => handleSendMessage()}
                >
                  Send
                </button>

              </div>
            </div>
        }
        {/* show desktop */}
        {
          // permissions.message_dialog_show &&
          <div className={classNames('chat', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}
          >
            {chat.map((message, i) => {
              return (
                <div key={i} className='chat-row'>
                  <div className='chat-row-left'>
                    {message.direction === 'incoming' &&
                      <div
                        className={'item left'}
                        style={{
                          opacity: message.is_read ? '.5' : '1',
                          cursor: 'pointer'
                        }}
                      // onMouseUp={(event) => handleChangeMenu(event, i)}
                      >
                        <p> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    src={item.url}
                                    alt="Client_img"
                                    onClick={() => handleOpenImage(item.url, item.type)}
                                  />
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} </span>
                      </div>}
                  </div>

                  <div className='chat-row-center'></div>

                  <div className='chat-row-right'>
                    {message.direction === 'outgoing' &&
                      <div
                        className={'item right'}
                        style={{ opacity: message.is_read ? '.5' : '1' }}
                      >
                        <p> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    src={item.url}
                                    alt="Client_img"
                                    onClick={() => handleOpenImage(item.url, item.type)}
                                  />
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} from <strong>{message.created_by}</strong></span>
                      </div>}
                  </div>
                </div>
              )
            })
            }
          </div>
        }

        {/* show laptop */}
        {
          // permissions.message_dialog_show &&
          <div className={classNames('chat', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}
          >
            {
              // permissions.message_dialog_show &&
              chat.map((message, i) => (
                <div key={i} className='chat-row'>
                  <div
                    className='chat-row-left'
                    style={{ width: '45%' }}
                  >
                    {
                      message.direction === 'incoming' &&
                      <div
                        className={'item left'}
                        style={{
                          opacity: message.is_read ? '.5' : '1',
                          cursor: 'pointer'
                        }}
                      // onMouseUp={(event) => handleChangeMenu(event, i)}
                      >
                        <p> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    src={item.url}
                                    alt="Client_img"
                                    onClick={() => handleOpenImage(item.url, item.type)}
                                  />
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} </span>
                      </div>
                    }
                  </div>

                  <div
                    style={{ width: '10%' }}
                    className='chat-row-center'
                  ></div>

                  <div
                    style={{ width: '45%' }}
                    className='chat-row-right'
                  >
                    {
                      message.direction === 'outgoing' &&
                      <div
                        className={'item right'}
                        style={{ opacity: message.is_read ? '.5' : '1' }}
                      >
                        <p> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    src={item.url}
                                    alt="Client_img"
                                    onClick={() => handleOpenImage(item.url, item.type)}
                                  />
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} from <strong>{message.created_by}</strong></span>
                      </div>
                    }
                  </div>
                </div>
              ))
            }
          </div>
        }

        {/* show mobile */}
        {
          // permissions.message_dialog_show &&
          <div className={classNames('chat', 'mobile-table', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}
          >
            {
              // permissions.message_dialog_show &&
              chat.map((message, i) => (
                <div key={i} className='chat-row'>
                  <div
                    className='chat-row-left'
                    style={{ width: message.direction === 'incoming' ? '80%' : '0' }}
                  >
                    {
                      message.direction === 'incoming' &&
                      <div
                        className={'item left'}
                        style={{
                          opacity: message.is_read ? '.5' : '1',
                          cursor: 'pointer'
                        }}
                      // onMouseUp={(event) => handleChangeMenu(event, i)}
                      >
                        <p> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    src={item.url}
                                    alt="Client_img"
                                    onClick={() => handleOpenImage(item.url, item.type)}
                                  />
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} </span>
                      </div>
                    }
                  </div>

                  <div
                    style={{ width: '10%' }}
                    className='chat-row-center'
                  ></div>

                  <div
                    style={{ width: message.direction === 'outgoing' ? '80%' : '0' }}
                    className='chat-row-right'
                  >
                    {
                      message.direction === 'outgoing' &&
                      <div
                        className={'item right'}
                        style={{ opacity: message.is_read ? '.5' : '1' }}
                      >
                        <p> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    src={item.url}
                                    alt="Client_img"
                                    onClick={() => handleOpenImage(item.url, item.type)}
                                  />
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} from <strong>{message.created_by}</strong></span>
                      </div>
                    }
                  </div>
                </div>
              ))
            }
          </div>
        }
      </div>

      {
        openModal &&
        <ModalImage
          image={zoomImage}
          setOpen={setOpenModal}
        />
      }
    </div >
  )
}
